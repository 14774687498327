



























import {
  ResultWorkOrderFinder,
  ResultWorkOrderForm,
} from "@/components/ResultWorkOrder";
import { ResultWorkOrderMapper } from "@/mapper/ResultWorkOrder.mapper";
import MNotification from "@/mixins/MNotification.vue";
import { ResultWorkOrderFormState } from "@/models/interface/result-work-order";
import { WorkOrderResponseDto } from "@/models/interface/work-order";
import Vue from "vue";

export default Vue.extend({
  name: "FormPage",
  mixins: [MNotification],
  components: {
    ResultWorkOrderFinder,
    ResultWorkOrderForm,
  },
  data() {
    return {
      workOrder: undefined as ResultWorkOrderFormState | undefined,
    };
  },
  computed: {
    queryFind(): { woNumber: string; csfNumber: string } | undefined {
      if (this.$route.query.wo_number && this.$route.query.csf_number) {
        return {
          woNumber: (this.$route.query.wo_number as string) ?? "",
          csfNumber: (this.$route.query.csf_number as string) ?? "",
        };
      }

      return undefined;
    },
  },
  methods: {
    onSuccess(data: WorkOrderResponseDto) {
      const payload = ResultWorkOrderMapper.toResultWorkOrderFormState(data);
      this.workOrder = { ...payload };
    },
    handleBack() {
      this.showConfirmationLeavePage(() => {
        this.$router.push({
          name: "logistic.work-order.result",
        });
      });
    },
  },
});
